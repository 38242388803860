import React from 'react';
import Homepage from './pages/homepage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BlogOne from './pages/blogOne';
import BlogTwo from './pages/blogTwo';
import BlogThree from './pages/blogThree'
import BlogFour from './pages/BlogFour';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/How-to-improve-your-conversion-rates-on-linkedIn" element={<BlogOne />} />
        <Route path="/How-to-Write-Cold-Message-Templates" element={<BlogTwo />} />
        <Route path="/How-to-sell-on-LinkedIn" element={<BlogThree />} />
        <Route path="/LinkedIn-sales-navigator-professional-vs-team" element={<BlogFour />} />
        {/* Define more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
