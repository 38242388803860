import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => (
  <Container fluid className="text-black text-center p-3" style={{ height: '10vh', backgroundColor: "#E0BF01"}}>
    <p>Message Ninja Blog Directory © 2023</p>
  </Container>
);

export default Footer;
