import React from 'react';
import Header from '../Components/header';
import Footer from '../Components/footer';

function BlogOne() {
  return (
    <div style={{ minHeight: '100vh' }}>
      <Header />
      <div className="container mt-5" style ={{width: "60%"}}>
        <h1 className="display-4">How to improve your conversion rates on linkedIn</h1>
        <hr className="my-4" />
        <p>Conversion rates for cold messaging have been on the decline across the board due to the rise of automated, low quality outreach by bots.</p>
        
        <p>Up until now, the easy response was to use a bot yourself to maintain your deal flow by sending more messages, <a href="https://messageninja.ai">Message Ninja</a> offers a new solution.</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src="https://media2.giphy.com/media/YnkMcHgNIMW4Yfmjxr/giphy.gif?cid=ecf05e478jpweyntjorr7bamsk7c4xqho05dehtb77zv83l3&ep=v1_gifs_search&rid=giphy.gif&ct=g" className="img-fluid rounded mb-3" alt="Growth Hub" style={{ width: '40%' }} />
        </div>
        <p>You can use <a href="https://messageninja.ai">Message Ninja</a> to reduce the time it takes to write highly converting messages that resonate with prospects on a personal level. It can achieve this in two ways:</p>
        <ul>
          <li>Instantly identifying compelling details on the prospects profile</li>
          <li>Generating high quality messages based on the detail you pick</li>
        </ul>

        <hr className="my-4" />
        <h2>Why personalise messages?</h2>
        <br />
        <p>Anyone who has used linkedIn in the last few years will know that its FULL of spam. Personalisation is the key to making a connection with potential clients or collaborators on LinkedIn. Your message copy, and by extension your personalisation, is the only lever you can really pull to increase conversion in a meaningful way.</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img style={{width: "80%"}} src="https://lh3.googleusercontent.com/fife/AK0iWDyknQfuXon7F7K-485NLrf479GFLUyEKT3B1ib5KsgdEgp4xYAQ8Q58xtTutEFcrPJZtNfwVqu3gTkEWdr28fftkVKZkCuRg8xVZvXeejvrbraXlWhZROBC1s4VE8Ab_4tqaqjPHEtmTXvVRySVathD0j2wYDyQD2e8rb1Lkk_ZPlcO-YGRxm0OkEX_-5Twv4y4v2BmD0o090TkM3sLoV0byO-tHhMm1iFsbp9aQ4z7g7TzeE_17TNQkruYOUfOdwgLr52OGZjS27EG90dW8r1w_8SrxEuOrCIcclOBvWaMvZIIVEswPGBc1EDRBPgIlAc6NJymG6lLGg4Rwg1yTkmeHCZSVayaIFfPv7oNMKGhLSW92a1Y88v6Ztr0jSJVRIWvWjlJqrl7de3a_HCqpfHNlgQiYX3Zkv3wAsVnDyVd70T_KPSju8-LTxIPrfbPRPoJN1LA4c0U_pOs3z0KNZz2wTSjlQA9CwSE1ZIkonIciG0zZ2DgxcFPS7lWxj0zyBn3b1gVq1VmYcK5Ts4d1PXwsWCcngu8QpYTjQTGyGGc28lcO132hDfKMie-07Vd4WckwEaVjSs1wqdimxPoQQvjm_Rl6ph8wF9VTToo6po1Q5feGyhsMq7ohyGD5LbTBmqUdrMoT5bQBadz49U_8RgUUqcS97TGXmLHBN91TgEh6Sm-m2PhLJbKhFOisX6c-4_rdsvxj2PZlBDeLeD2xDH-k9Igk230EBucr0SGLVz6WeljjJgVZsQtnJRzm-Z1ovhzi5tkyy36P1V3aYr7P_PgIi8NwTMkE8mwRmW7cScQFTDHKRt0YluAkUNtX2BTklCZDdD2ilWhIUyrmK5qfpvM2rS0uNCi-B2WO2qf0jTqqUi-ANZAC-5rtPdsblLHl_AI11rcVPzQiQ-Muk96YjSrLbnn3uLFcmHzIBbCyvE2lr3w-s3XIjVQBG1pm7fhKRwJ9XUEoWwBJLtsbYbnohIGDrgaJ1eecGfJBcwbEv68sH-KFacyxCe9EEerAHHkhMXbwsqIoaBEL2gQgSOuYRrmPM1d2R8rDmfZIcL8zEPBzGTyFS5gZnFtMdbLVuOmaxiZcM8UxMtk1QSG_rGvbkY2Tj1ZPxW6PTqO5YTNiEISdr9w-Dm7V3C0y3wlEH4ozj72_22BJ_dzgczv_lIb1__wqePi-bpj09KLIejQr3JVj2xvMntEYHcgoDLTyZWalKIJor_i7aK1IOOkoeZ4pYKWC9h5MEWOpiyoF2HChYzXNRO3cU-hugqoefY9bV7_vuYcb1Owu73XWcWEYq60W9tXJWl6j12AGKvAfsRU3UUfe5WmYAKT-F5IoKYWe657rCX10QplUStQX2A4261Mgz7g3OTTTrVgDG35Kr3UiEQWQacyWBjEIoA-2VxH99PrtoOf1fjPxCPUDx1yyEA3zF-kGsF2SewjCk2gQZbu8sH6k718e1Ju1e2zXA1ynuswyHZ3L0gHQutqR404BHvMmHyPYbFvBN6TMitUFFtrKuRQsugXZROYZaK0qZpZD9MiKan9OPRMkTQ=w2880-h1514" className="img-fluid rounded mb-3" alt="Screenshot" />
        </div>
        <hr className="my-4" />
        <h2>3 Strategies to Improve your personalisation</h2>
        <br />
        <ul>
          <li>Focus on a recent post: By default, <a href="https://messageninja.ai">Message Ninja</a> will comment on any recent post on the prospects profile. These messages are fantastic, as they make it clear you have done your research.</li>
          <br />
          <li>Edit the message yourself to add your own personality: Soon <a href="https://messageninja.ai">Message Ninja</a> will be able to mirror your writing style, but its not there yet. Try clicking on the generated message to edit it so its exactly the way you want.</li>
          <br />
          <li>Try sending a voice note: Voice notes are a pretty big deal for SDR’s right now. They get the prospect intrigued and they are something that your prospect wont have seen every day. You can use <a href="https://messageninja.ai">Message Ninja</a> to generate a personalised intro and use it as a script for the Voice Note.</li>
          <br />
        </ul>
        <hr className="my-4" />
        <h2>The Takeaway</h2>
        <br />
        <p>With <a href="https://messageninja.ai">Message Ninja</a>, you're not just sending messages; you're building relationships. It's time to revolutionise the way you do LinkedIn outreach and see your conversion rates go through the roof.</p>
        <p>If you haven’t already, you can check it out <a href="https://messageninja.ai">here</a></p>
        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
}

export default BlogOne;
