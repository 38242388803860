import React from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import '../index.css';


const BlogList = () => {
  // Assume blogs is an array of blog objects
  const blogs = [
    { title: "How to improve your conversion rates on linkedIn", description: "This article presents Message Ninja, a tool to enhance LinkedIn cold messaging and improve conversion rates, countering the decline due to automated, low-quality outreach. It stresses the importance of personalization in messages, and demonstrates Message Ninja helping tailor messages based on prospects' profiles. ", url: "/How-to-improve-your-conversion-rates-on-linkedIn", img: "1.gif"},
    { title: "How to write cold message templates", description: "In an era of plunging conversion rates, a having a “belter” cold message template is essential, especially for professionals leveraging platforms like LinkedIn for networking and sales. This blog post explores my framework for writing “belter” cold messages", url: "/How-to-Write-Cold-Message-Templates", img: "2.gif" }, 
    { title: "How to sell on linkedIn", description: " This guide offers insights into leveraging LinkedIn as a platform for sales and networking. It emphasizes relationship-building, trust establishment, and standing out in a competitive digital environment. Key areas include crafting a strong profile, effective prospecting, strategic networking, sending personalized direct messages", url: "/How-to-sell-on-LinkedIn", img: "3.gif" },
    { title: "LinkedIn Sales Navigator: Professional vs. Team", description: "In the world of sales, having the right tools is like having the perfect set of golf clubs - it can be a game changer. Today, let’s talk about LinkedIn Sales Navigator, specifically comparing the Professional and Team versions. It’s like choosing between a standard driver and a high-tech one with all the bells and whistles.", url: "/LinkedIn-sales-navigator-professional-vs-team", img: "4.gif" },]

  return (
    <Container  style={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Row style={{ paddingTop: '20px', paddingBottom: '10px' }}>
        {blogs.map((blog, index) => (
          <Col key={index} md={12}>
            <Card style={{ minHeight: '15vh', border: 'none', }} className='highlightOnHover' onClick={() => window.open(blog.url, "_blank")}>
              <Card.Body>
                <Row style={{height: "100%"}}>
                    <Col md={3} >
                        <Card.Img variant="top" src={blog.img} style={{height: "140px", width: "100%", objectFit: "cover", borderRadius: "5px"}} />
                    </Col>

                    <Col md={9} style={{minWidth: "0"}}>
                        <Card.Title>{blog.title}</Card.Title>
                        <Card.Text>{blog.description}</Card.Text>
                    </Col>
                    {/* <Col md={2} className="d-flex align-items-center" style={{minWidth: "0"}}>
                        <Button style={{backgroundColor: "#E0BF01", color: "black", borderColor:"grey"}} > 📕 Read More 📕</Button>
                    </Col> */}
                </Row>
              </Card.Body>
            </Card>
            <hr className="my-4" />
          </Col>
          
        ))}
      </Row>
    </Container>
  );
};

export default BlogList;
